import React, { useState, useEffect } from "react";

import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Footer from "../components/Footer";
import "../css/contact.scss";

import { Helmet } from "react-helmet";

// const Contact = () => {
function Contact() {
  const { t } = useTranslation();

  // useEffect(() => {
  //   console.log("--- value after: ", value)
  // }, [value])

  return (
    <>
      <Layout>
        <Helmet>
          {/* <title>{t("header")}</title> */}
          <title>{t("header")}</title>
        </Helmet>
        <main className="main">
          <div className="page page-markets">
            <div className="page-title w-2/3 md:w-auto">
              <div className="page-title__name">{t("title")}</div>
            </div>

            <div className="page-header">
              <h1>{t("header")}</h1>
            </div>

            <div className="row box box_contacts">
              <div className="col box-contact-data w-full md:w-1/2">
                <div className="box-contact__block">
                  <div className="box-contact__name">{t("phoneHeader")}</div>
                  <div className="box-contact__phone">
                    <a href="tel:+79166623924" className="">
                      {t("phoneNumber")}
                    </a>
                  </div>
                </div>
                <div className="box-contact__block">
                  <div className="box-contact__name">
                    {t("salesMailHeader")}
                  </div>
                  <div className="box-contact__mail">
                    <a
                      href={"mailto:" + t("salesMailValue")}
                      className=""
                      dangerouslySetInnerHTML={{ __html: t("salesMailText") }}
                    ></a>
                  </div>
                </div>
                <div className="box-contact__block">
                  <div className="box-contact__name">
                    {t("generalMailHeader")}
                  </div>
                  <div className="box-contact__mail">
                    <a
                      href={"mailto:" + t("generalMailValue")}
                      className=""
                      dangerouslySetInnerHTML={{ __html: t("generalMailText") }}
                    ></a>
                  </div>
                </div>
                <div className="box-contact__block">
                  <div className="box-contact__name">{t("addressHeader")}</div>
                  <div className="box-contact__address box__text">
                    <Trans>{t("addressText")}</Trans>
                  </div>
                </div>
              </div>

              <div className="col box-contact-data w-full md:w-1/2">
                <div className="contact-form">
                  <form>
                    <div className="form__group">
                      <input
                        className="form__input dot-underline"
                        id="contactName"
                        type="text"
                        required
                        // placeholder="Имя"
                      />
                      <label className="form__label" htmlFor="contactName">
                        {t("formName")}
                      </label>
                    </div>
                    <div className="form__group">
                      <input
                        className="form__input dot-underline"
                        id="contactPhone"
                        type="text"
                        required
                        // placeholder="Телефон"
                      />
                      <label className="form__label" htmlFor="contactPhone">
                        {t("formPhone")}
                      </label>
                    </div>
                    <div className="form__group">
                      <input
                        className="form__input dot-underline"
                        id="contactCompany"
                        type="text"
                        required
                      />
                      <label className="form__label" htmlFor="contactCompany">
                        {t("formCompany")}
                      </label>
                    </div>
                    <div className="form__group">
                      <input
                        className="form__input dot-underline"
                        id="contactEmail"
                        type="text"
                        required
                      />
                      <label className="form__label" htmlFor="contactEmail">
                        {t("formMail")}
                      </label>
                    </div>
                    <div className="form__group">
                      {t("formMessage")}
                      <textarea
                        placeholder=""
                        className="form__textarea"
                        id="contactMessage"
                      ></textarea>
                    </div>
                    <div className="row w-full form-action-line">
                      <div className="col w-1/3 contact-btn-wrapper">
                        <button disabled className="contact-form-button">
                          {t("btnSend")}
                        </button>
                      </div>
                      <div className="col md:w-1/3 lg-hidden"></div>
                      <div className="col w-1/3 contact-form__notes">
                        {t("formSafety")}
                      </div>
                      {/* <div className="col w-1/3">.</div> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </Layout>
    </>
  );
}

export default Contact;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["contact", "common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
